<template>
  <div class="jh-container">
      <div class="jh-ui-header">
          <h1>상담유형별통계</h1>
          <div class="is-right">
          </div>
      </div>

  <!--조회-->
      <div class="jh-search-form">
          <table>
              <colgroup>
                  <col width="100px">
                  <col width="100px">
                  <col width="100px">
                  <col width="300px">
                  <col width="100px">
                  <col width="150px">
                  <col width="150px">
                  <col width="150px">
                  <col width="50px">
                  <col width="80px">
              </colgroup>
              <tr>
                  <th><label class="is-required">조회유형</label></th>
                  <td><v-select class="jh-form" v-model="cnslInfo.SRCH_TY" :items="SRCH_TY" item-text="CD_NM" item-value="CD"></v-select></td>
                  <th><label class="is-required">조회기간</label></th>
                  <td>
                      <div class="jh-cols" v-if="this.cnslInfo.SRCH_TY === 'D'">                        
                          <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                            </v-menu>
                      </div>
                    <div class="jh-cols" v-else>                        
                      <v-menu v-model="menuStartMonth" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="fromMonth" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartMonth = false"
                          maxlength="7" @input="fromMonthOnInput" @click:clear="months[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"></v-text-field>
                          </template>
                          <v-date-picker class="jh-calendar" type="month" v-model="months[0]" @input="menuStartMonth = false" no-title locale="ko-KR" :max="months[1]" :day-format="mixin_getDate" @change="changeDate('startMonth')"></v-date-picker>
                      </v-menu>
                      <span class="jh-unit">~</span>                       
                      <v-menu v-model="menuEndMonth" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="toMonth" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndMonth = false"
                          maxlength="7" @input="toMonthOnInput" @click:clear="months[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"></v-text-field>
                          </template>
                          <v-date-picker class="jh-calendar" type="month" v-model="months[1]" @input="menuEndMonth = false" no-title locale="ko-KR" :min="months[0]" :day-format="mixin_getDate" @change="changeDate('endMonth')"></v-date-picker>
                      </v-menu>
                    </div>                      
                  </td>
                  <th><label class="jh-form">소속</label></th>
                  <td><v-select class="jh-form" v-model="ATTR_A" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" @change="changeDept(ATTR_A)"></v-select></td>
                  <td><v-select class="jh-form" v-model="ATTR_B" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" @change="changeUser(ATTR_A, ATTR_B)"></v-select></td>
                  <td><v-select class="jh-form" v-model="CNSL_ID" item-value="USER_ID" item-text="USER_NM" :items="CNSL_COMBO"></v-select></td>
                  <td><v-btn class="jh-btn is-fill has-icon-only" @click="showDialog('P101801')"><i class="jh-icon-search-sm is-white"></i></v-btn></td>
              </tr>
              <tr>
                  <th><label>구분</label></th>
                  <td colspan="2">
                      <input type="radio" class="jh-form" name="rdoCnslType" id="rdoTp1"  value="1" v-model="selTypeCd" ><label for="rdoTp1">대분류</label>
                      <input type="radio" class="jh-form" name="rdoCnslType" id="rdoTp2"  value="2" v-model="selTypeCd" ><label for="rdoTp2">중분류</label>
                      <input type="radio" class="jh-form" name="rdoCnslType" id="rdoTp3"  value="3" v-model="selTypeCd" ><label for="rdoTp3">전체</label></td>
                  <td colspan="5">
                    <div class="jh-cols">
                        <v-select class="jh-form"
                             v-model="cnslInfo.CNSL_TY_LCLS" 
                             :items="CNSL_TY_CODE_1"
                             item-text="CD_NM"
                             item-value="CD"
                             no-data-text="조회된 값이 없습니다"
                             @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_LCLS, '1')"
                          ></v-select>
                        <v-select class="jh-form" 
                          v-model="cnslInfo.CNSL_TY_MDCLS" 
                          :items="CNSL_TY_CODE_2"
                          item-text="CD_NM"
                          item-value="CD"
                          no-data-text="조회된 값이 없습니다"
                          @change="changeCounselTypeKey(ASP_CUST_KEY, cnslInfo.CNSL_TY_MDCLS, '2')"
                      ></v-select>
                      <v-select class="jh-form"
                          v-model="cnslInfo.CNSL_TY_SCLS" 
                          :items="CNSL_TY_CODE_3"
                          item-text="CD_NM"
                          item-value="CD"
                          no-data-text="조회된 값이 없습니다"
                      ></v-select>
                    </div>
                  </td>
                  <th><label>채널</label></th>
                  <td><v-select class="jh-form" v-model="CHNN_CD" style="width:80px" :items="mixin_common_code_get(this.common_code, 'CS01', '전체')"></v-select></td>                  
                  <td class="has-search"><v-btn class="jh-btn is-search" @click="getList" :loading="isLoading">조회</v-btn></td>
              </tr>
          </table>
      </div>
  <!--//조회-->
  <!--리스트-->
      <div class="jh-form-wrap">
          <div class="jh-ui-header">
              <h2>상담유형별 통계</h2>
              <div class="is-right">
                  <v-btn class="jh-btn is-light" @click="downloadExcel('cnslTypeTable', '상담유형별통계', '상담유형별통계목록')">엑셀다운로드</v-btn>
              </div>
          </div>
          <data-tables
              ref="dataTable"
              id="cnslTypeTable"
              dataTableClass="has-control"
              :data-table-options="dataTableOptions"
              @click:row="onClickRow"
              >
          </data-tables>
      </div>
      <!--//리스트-->
      <v-dialog max-width="1000" v-model="dialogP101801">
          <dialog-P101801
          @hide="hideDialog('P101801')"
          :param="param"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('P101801'), setUserData()]"
          ></dialog-P101801>
          <template v-slot:body></template>
      </v-dialog>
  </div>
</template>

<script>

import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";
import dialogP101801 from "./P101801.vue";

export default {
  name: "MENU_E010601", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
      dialogP101801,
      dataTables
  },
  data() {
      return {
          CHNN_CD:"",
          dialogP101801: false,
          menuStartDate: false,
          menuEndDate: false,        
          menuStartMonth: false,
          menuEndMonth: false,   
          fromMonth: moment().format("YYYY-MM"),
          toMonth: moment().format("YYYY-MM"),  
          months: [
            new Date().toISOString().substr(0, 7),
            new Date().toISOString().substr(0, 7)
          ],                         
          isLoading: false,
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
          dates: [
              (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          ],
          ASP_CUST_KEY: '001',
          userInfo: {},
          cnslInfo: {}, //상담 정보 객체
          formData:{
              USER_ID : "",
              USER_NM : "",
              USER_NICK : "",
              USER_STATUS_CD : "",
              USER_ATTR_A : [],
              USER_ATTR_B : [],
              ASP_NEWCUST_KEY: "001",
          },
          param: {
              page: 'E010601',
              type: ''
          },
          ATTR_A: "",
          ATTR_B: "",
          CNSL_ID: "",
          selTypeCd: '1',
          cdCRS000: [],
          codeDS38: [],

          preDate: [],
          gridCnslhistItems: [],

          CNSL_TY_CODE_1: [], // 상담유형(대)
          CNSL_TY_CODE_2: [], // 상담유형(중)
          CNSL_TY_CODE_3: [], // 상담유형(소)

          DEPT_COMBO: [], // 부서콤보
          TEAM_COMBO: [], // 팀콤보
          CNSL_COMBO: [], // 상담원콤보

          common_code: [], //공통코드
          selectedRow: [], //선택된 로우
          SRCH_TY : [
            {CD: 'D',CD_NM: '일'},
            {CD: 'M',CD_NM: '월'}
          ],
          headers: [
              { text: '대분류', value: 'CNSL_TY_LCLS_NM' , align: 'left', width: '24%', sortable: false },
              { text: '중분류', value: 'CNSL_TY_MDCLS_NM', align: 'left', width: '24%', sortable: false },
              { text: '소분류', value: 'CNSL_TY_SCLS_NM' , align: 'left', width: '24%', sortable: false },
              { text: '건수'  , value: 'TY_COUNT', align: 'right', width: '15%', },
              { text: '%'     , value: 'TY_AVG', align: 'right', width: '13%', }
          ],
          dataTableOptions: {
              fixedHeader: true,
              hideDefaultFooter: true,
              height: '570px',
              itemKey: 'index',
              itemsPerPage: 50,
              noDataText: "조회된 결과가 없습니다.",
              page: 1,
              singleSelect: true,
              singleExpand: false,
              load: this.selectCnslTypeList,
              disablePagination: true,
          },
           
      }
  },
  computed: {
      ...mapGetters({
          geCustInfo: 'callStore/GE_CUST_INFO'
      }),
      
      dataTableInstance: function() {
          return this.$refs.dataTable;
      },
  },
  created() { 
      this.dataTableOptions.headers = this.headers;

      this.DEPT_COMBO = [{CD: null, CD_NM: '전체'}];
      this.TEAM_COMBO = [{CD: null, CD_NM: '전체'}];
      this.CNSL_COMBO = [{USER_ID: null, USER_NM: '전체'}];

      this.cnslInfo.DEPT_CD = '';
      this.cnslInfo.TEAM_CD = '';
      this.cnslInfo.CNSL_USID = '';

      this.getAttrDiv('A');
  },
  mounted() {
      this.ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ASP_CUST_KEY"];
      this.USER_ID = this.$store.getters["userStore/GE_USER_ID"];
      this.USER_NM = this.$store.getters["userStore/GE_USER_NAME"];
      this.cnslInfo.CLNT_NO = this.geCustInfo.CLNT_NO
      this.cnslInfo.CUST_NM = this.geCustInfo.CNM

      this.callCommonCode();
      this.initData();
      this.getAttrList();
  },
  watch: {
  },
  methods: {
      async callCommonCode(){
        let codeName = ['CS01'];
        this.common_code = await this.mixin_common_code_get_all(codeName);  
               
        //   if(this.preDate) this.preDate = await this.mixin_getSvrPrevDate("%Y%m%d");

        //   this.dates[0] = this.mixin_dateFormat(this.preDate.TO_DAY_7);
        //   this.dates[1] = this.mixin_dateFormat(this.preDate.TO_DAY);
      },

      async initData() {
          this.isLoading = false;
          this.cnslInfo = {
              SRCH_TY: "M",
              CONTT_CD: "",
              CHNN_CD: "",
              CUS_LEVEL_CD:"",
              CALL_TIME: 0
          };

          this.ATTR_A = "";
          this.ATTR_B = "";
          this.cnslInfo.CNSL_USID = "";

          let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];

          this.userInfo.USER_ATTR_A = get_user_role.USER_ATTR_A;
          this.userInfo.USER_ATTR_B = get_user_role.USER_ATTR_B;
          this.userInfo.USER_ID = get_user_role.userId;

          this.setUserData();

          if(this.dates[0].length >= 8) {
              let frDate = this.dates[0].substr(0,7) + "01"
              this.dates[0] = this.mixin_dateFormat(frDate);
              this.startDate(this.dates[0]);
          }

          if(!this.ASP_CUST_KEY) this.ASP_CUST_KEY = "001";
          this.changeCounselTypeKey(this.ASP_CUST_KEY, "*", "0");
      },
      getList(loadOptions) {
          if(!this.cnslInfo.CNSL_BEGIN_DATE){
              this.common_alert("상담일자는 필수입니다.", "error");
              //return false
          }
          this.isLoading = true;

          if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
          else this.dataTableInstance.loadData();
      },
      selectCnslTypeList: async function (loadOptions) {

            let DT1 = this.dates[0];
            let DT1_Array = DT1.split("-");
            var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];

            let DT2 = this.dates[1];
            let DT2_Array = DT2.split("-");
            var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

            if (this.cnslInfo.SRCH_TY == "M"){
                let temp_ST = this.months[0].split("-")
                let temp_END = this.months[1].split("-")

                ST_DATE = temp_ST[0] + temp_ST[1] + "01";
                END_DATE = temp_END[0] + temp_END[1] + "31";
            }

          // header 세팅
          let requestData = {
              headers: {
              ASYNC: false,
              METHOD: "list",
              SERVICE: "statistics.phone.cnslt-sttc",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: loadOptions.itemsPerPage,
              PAGES_CNT: loadOptions.page,
              URL: "/api/statistics/phone/cnslt-sttc/inqire"
              },
              sendData:  this.cnslInfo,
          };
            console.log("this.CHNN_CD : ", this.CHNN_CD)
          //구분선택
          requestData.sendData["CNSL_TY"] = this.selTypeCd;
          requestData.sendData["CHNN_CD"] = this.CHNN_CD;
 
          requestData.sendData["CNSL_BEGIN_DATE"] = ST_DATE
          requestData.sendData["CNSL_END_DATE"] = END_DATE
          requestData.sendData["CNSL_BEGIN_MONTH"] = ST_DATE
          requestData.sendData["CNSL_END_MONTH"] = END_DATE        
          requestData.sendData["ASP_CUST_KEY"]  = this.ASP_CUST_KEY;
          requestData.sendData["DEPT_CD"] = this.ATTR_A;
          requestData.sendData["TEAM_CD"] = this.ATTR_B;
          requestData.sendData["CNSL_USID"] = this.CNSL_ID;


          if (loadOptions.sortBy && loadOptions.sortBy.length > 0) {
              requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
              if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0) {
                  requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
              }
          } else {
              requestData.sendData.SORT_DRCT = "desc";
              requestData.sendData.SORT_ORDR = "CNSL_TY_LCLS";
          }
          return this.common_postCall(requestData).then(response => {
              this.gridCnslTypeItems = [];
              if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                  this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                  return false;
              }

              let header = response.HEADER;
              let data = response.DATA;
              let count = 1;
              if (!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                  if(!this.mixin_isEmpty(response.DATA) && response.DATA.length > 0) {
                      for(let d of response.DATA) {
                          for(let k of Object.keys(d)) {
                              if(k === 'CNSL_BDATE') d[k] = this.mixin_dateFormat(d[k])
                              else if(k === 'CNSL_BEGIN_TM') d[k] = this.mixin_timeFormat(d[k])
                              else if(k === 'CNSL_END_TM')   d[k] = this.mixin_timeFormat(d[k])
                              else if(k === 'ACCT_NO')       d[k] = this.mixin_accNoFormat(d[k])
                              else if(k === 'SEND_MAN_NO')   d[k] = this.mixin_newPhoneFormat(d[k])
                              else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                          }

                          d.index = count++;
                          this.gridCnslTypeItems.push(d)
                      }
                  }
              }
              console.log("gridCnslTypeItems  ", this.gridCnslTypeItems)
              this.isLoading = false;
              return {
                  data: this.gridCnslTypeItems,
                  totalCount: this.gridCnslTypeItems.length ,
              };
              this.common_alert("정상 처리되었습니다.", "done");
          });
      },
      changeCounselTypeKey: async function (asp_cust_key, spst_counsel_typ_cd, data_type) {
          let CNSL = await this.mixin_counsel_type_code_div_get(
              "Y",
              this.ASP_CUST_KEY,
              "P",
              spst_counsel_typ_cd,
              "전체"
          );
          if (data_type === "0") {
              this.CNSL_TY_CODE_1 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
              this.cnslInfo.CNSL_TY_LCLS  = '';
              this.cnslInfo.CNSL_TY_MDCLS = '';
              this.cnslInfo.CNSL_TY_SCLS  = '';

              this.CNSL_TY_CODE_1 = CNSL;
          } else if (data_type === "1") {
              if(this.cnslInfo.CNSL_TY_LCLS == "" || this.cnslInfo.CNSL_TY_LCLS == undefined) {
                  this.CNSL_TY_CODE_2 = [{ CD: "", CD_NM: "전체" }];
                  this.cnslInfo.CNSL_TY_MDCLS = '';
              } else {
                  this.CNSL_TY_CODE_2 = CNSL;
                  this.cnslInfo.CNSL_TY_MDCLS = '';
              }

              this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
              this.cnslInfo.CNSL_TY_SCLS = '';
          } else if (data_type === "2") {
              if(this.cnslInfo.CNSL_TY_MDCLS == "" || this.cnslInfo.CNSL_TY_MDCLS == undefined) {
                  this.CNSL_TY_CODE_3 = [{ CD: "", CD_NM: "전체" }];
                  this.cnslInfo.CNSL_TY_SCLS = '';
              } else {
                  this.CNSL_TY_CODE_3 = CNSL;
                  this.cnslInfo.CNSL_TY_SCLS = '';
              }
          }
      },
      fnLvlChng(val1) {
          for(let item of this.codeDS38) {
              if(val1 == item.value) this.cnslInfo.CUS_LEVEL = item.text;
          }
      },
       changeDate(type) {
        
            if(type == 'start') {
                if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[0] = date;
                    this.from = date;
                    return;
                } else {
                    this.from = this.dates[0];
                }
            } else if (type == 'end') {
                if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[1] = date;
                    this.to = date;
                    return;
                } else {
                    this.to = this.dates[1];
                }
            }else if(type == 'startMonth') {
                
               if (moment(this.months[0]).isBefore(moment(this.months[1]).subtract(3, 'month').format('YYYY-MM'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM");
                    this.months[0] = date;
                    this.fromMonth = date;
                    return;
                } else {
                    this.fromMonth = this.months[0];
                }
            } else if (type == 'endMonth') {
                if (moment(this.months[1]).isAfter(moment(this.months[0]).add(3, 'month').format('YYYY-MM'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM");
                    this.months[1] = date;
                    this.toMonth = date;
                    return;
                } else {
                    this.toMonth = this.months[1];
                }
            }
        },
      startDate(e) {
          this.from = e;
      },
      endDate(e) {
          this.to = e;
      },
      fromOnInput: function() {
          // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
          if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
              // 종료일자보다 이후를 입력했으면 종료일자로 변경
              if (moment(this.from).isAfter(this.dates[1])) {
                  this.$nextTick(() => {
                      this.from = this.dates[1];
                  });
              }

              this.$nextTick(() => {
                  this.dates[0] = this.from;
                  // datepick 다시 랜더링
                  this.menuStartDate = false;
                  this.menuStartDate = true;
              });
          }
      },
      toOnInput: function() {
          // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
          if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
              // 시작일자보다 이전을 입력했으면 시작일자로 변경
              if (moment(this.to).isBefore(this.dates[0])) {
                  this.$nextTick(() => {
                      this.to = this.dates[0];
                  });
              }

              this.$nextTick(() => {
                  this.dates[1] = this.to;
                  // datepick 다시 랜더링
                  this.menuEndDate = false;
                  this.menuEndDate = true;
              });
          }
      },
      fromMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.fromMonth) && moment(this.fromMonth).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.fromMonth).isAfter(this.months[1])) {
          this.$nextTick(() => {
            this.fromMonth = this.months[1];
          });
        }

        this.$nextTick(() => {
          this.months[0] = this.fromMonth;
          // datepick 다시 랜더링
          this.menuStartMonth = false;
          this.menuStartMonth = true;
        });
      }
    },
    startMonth(e) {
      this.fromMonth = e;
    },
    toMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.toMonth) && moment(this.toMonth).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.toMonth).isBefore(this.months[0])) {
          this.$nextTick(() => {
            this.toMonth = this.months[0];
          });
        }

        this.$nextTick(() => {
          this.months[1] = this.toMonth;
          // datepick 다시 랜더링
          this.menuEndMonth = false;
          this.menuEndMonth = true;
        });
      }
    },
    endMonth(e) {
      this.toMonth = e;
    },
      onClickRow: function (item, row) {
        //   if (this.selectedRow != "") {
        //       this.activeRow(this.selectedRow, false);
        //       this.selectedRow = [];
        //   }
        //   this.activeRow(row, true);
        if(this.selectedId===item.index){
          row.select(false);
          this.selectedId = item.CNSL_TY_LCLS_NM;
        }else{
          row.select(true);
          this.selectedId = item.index;
        }              
      },
      activeRow(row, value) {
          this.selectedRow = row;
          row.select(Boolean(value));
          row.isSelected = Boolean(value);
      },
      setUser(userData){
          let user = {
              USER_ID: this.userData.USER_ID,
              USER_NICK:this.userData.USER_NICK,
              USER_NM:this.userData.USER_NM,
          };
          this.userInfo = user;
      },
      async setUserData(){
          this.formData.USER_ID = this.userInfo.USER_ID;
          this.formData.USER_NM = this.userInfo.USER_NM;

          this.ATTR_A = '';
          this.ATTR_B = '';
      },
      async changeDept(attrA){
          this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', attrA);

          this.CNSL_ID = '';
          this.ATTR_B = '';
      },
      async changeUser(attrA, attrB){
          this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
          this.CNSL_ID = '';
      },
      //사용자 소속 콤보박스 생성
      getAttrDiv(attr) {
          api.post("api/palette/common/get-attr-div/list",   //api url입력
          {
              ATTR_CD: attr,
              ATTR_DIV_CD: '',
          },
          {head: {
              "SERVICE" : "palette.common.get-attr-div",
              "METHOD" : "list",
              "TYPE" : "BIZ_SERVICE",
          }})
          .then((response) => {
              if (attr == 'A'){
              this.DEPT_COMBO = [...this.DEPT_COMBO, ...response.data.DATA];
              } else if (attr == "B") {
              this.TEAM_COMBO = [...this.TEAM_COMBO, ...response.data.DATA];
              }
          })
          .catch((err) => {
              alert(err);
          })
      },
      //Table row 클릭이벤트
      rowClick1(item, row) {
        //   if (item != null) {
        //       this.activeRow(row, true);
        //   }
        if(this.selectedId===item.index){
          row.select(false);
          this.selectedId = item.CNSL_TY_LCLS_NM;
        }else{
          row.select(true);
          this.selectedId = item.index;
        }          
      },
    //   activeRow(row, value) {
    //       this.selectedRow = row;
    //       row.select(Boolean(value));
    //       row.isSelected = Boolean(value);
    //   },
      downloadExcel(tableId, fileNm, sheetNm){
       //number,date,time,string - n,d,t,s
        var exceltypeArr = {};
        exceltypeArr[0] = "s"; 
        exceltypeArr[1] = "s"; 
        exceltypeArr[2] = "s"; 
        exceltypeArr[3] = "n"; 
        exceltypeArr[4] = "n"; 
            
          //엑셀다운로드
          this.table_id   = tableId;
          this.file_name  = fileNm;
          this.sheet_name = sheetNm;
          this.mixin_common_exportExcel(exceltypeArr);
      },
      showDialog(type) {
          //팝업 파라미터 설정
          this.popupParams;
          this[`dialog${type}`] = true;
      },
      hideDialog(type) {
          this[`dialog${type}`] = false;
      },
      submitDialog(type){
          console.log('완료');
          this[`dialog${type}`] = false
      },
      async getAttrList() {
          this.DEPT_COMBO = await this.mixin_get_attr_by_attr('****', '');
          this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
          this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];

          this.ATTR_A = '';
          this.ATTR_B = '';
          this.CNSL_ID = '';
      }
  }
};
</script>

<style></style>  